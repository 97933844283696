import FooterComponent from "./components/footer-component";
import SocialItemComponent from "./components/social-item-component";
import {renderContact} from "./render-contact";
import {renderNav} from "./render-nav";
import {RenderPosition, render} from "./utils/render";

const firstMenuItemList = [
  {
    id: `about`,
    title: `О компании`,
    link: `/about`
  },
  {
    id: `response`,
    title: `Обратная связь`,
    link: `/response`
  },
  {
    id: `job`,
    title: `Вакансии`,
    link: `/jobs?resume`
  },
  {
    id: `news`,
    title: `Новости`,
    link: `/news`
  },
  {
    id: `franchise`,
    title: `Сотрудничество`,
    link: `/fr`
  },
  {
    id: `policy`,
    title: `Политика конфиденциальности`,
    link: `/policy`
  },
  {
    id: `oferta`,
    title: `Пользовательское соглашение`,
    link: `/oferta`
  },
];

const secondMenuItemList = [
  {
    id: `bonus`,
    title: `Бонусная программа`,
    link: `/oplata#bonus`
  },
  {
    id: `credit`,
    title: `Кредиты`,
    link: `/credit`
  },
  {
    id: `delivery`,
    title: `Доставка`,
    link: `/delivery`
  },
  {
    id: `pay`,
    title: `Способ оплаты`,
    link: `/oplata`
  },
  {
    id: `garant`,
    title: `Обмен и возврат товара`,
    link: `/garant`
  },
];

const SocialITems = [
  {
    id: `vk`,
    title: `ВКонтакте`,
  },
];

const renderSocialList = (container, shopInfoData) => {
  SocialITems.forEach(({id, title}) => {
    const itemComponent = new SocialItemComponent({
      id,
      title,
      link: shopInfoData[`${id}_group`]
    });
    render(container, itemComponent.getElement(), RenderPosition.BEFOREEND);
  });
};


export const renderFooter = (shopInfoData, logoData) => {
  const container = document.querySelector(`.main-footer`);
  if (!container) {
    return;
  }

  const component = new FooterComponent({});
  const contactElement = component.getElement().querySelector(`.contact`);
  renderContact(contactElement, shopInfoData, logoData);

  const socialListElement = component.getElement().querySelector(`.social__list`);
  renderSocialList(socialListElement, shopInfoData);

  const navWrapElement = component.getElement().querySelector(`.footer-nav`);
  const listClassName = `footer-nav__list`;
  const itemClassName = `footer-nav__item`;
  renderNav(navWrapElement, firstMenuItemList.filter((item) => !(item.id === `franchise` && !shopInfoData.show_fr)), listClassName, itemClassName);
  renderNav(navWrapElement, secondMenuItemList.filter((item) => !(item.id === `bonus` && !shopInfoData.show_bonus)), listClassName, itemClassName);

  render(container, component.getElement(), RenderPosition.BEFOREEND);
};
