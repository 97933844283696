import AbstractComponent from "./abstract-component";


const createTemplate = (alphabetCity) => {
  const citiesMarkup = alphabetCity.cities
    .map((city) => {
      return (
        `<li class="cities__subitem">
          <button type="button" class="cities__cityButton" data-city-id="${city.cityId}" data-active="${city.isActive}">${city.title}</button>
        </li>`
      );
    }).join(`\n`);

  return (
    `<li class="cities__item">
      ${alphabetCity.firstLetter}
      <ul class="cities__sublist">
        ${citiesMarkup}
      </ul>
    </li>`
  );
};

export default class CitiesComponent extends AbstractComponent {
  constructor({alphabetCity = {}}) {
    super();

    this._alphabetCity = alphabetCity;
  }

  getTemplate() {
    return createTemplate(this._alphabetCity);
  }

  getCityButtonClickHandler(handler) {
    const buttons = this.getElement().querySelectorAll(`.cities__cityButton`);
    buttons.forEach((button) => {
      button.addEventListener(`click`, () => handler(button.dataset.cityId));
    });
  }
}
