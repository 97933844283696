import AbstractComponent from "./abstract-component";

const createTemplate = () => {
  return (
    `<div class="main-footer__wrap container">
      <div class="main-footer__footer-nav footer-nav"></div>

      <div class="main-footer__contacts">
        <div class="main-footer__contact contact"></div>
        <div class="main-footer__social social">
          <ul class="social__list"></ul>
        </div>
        <a class="main-footer__addresses" href="/contact">Контакты</a>
      </div>

      <p class="main-footer__copyright">© 2012—${new Date().getFullYear()}</p>
    </div>`
  );
};

export default class FooterComponent extends AbstractComponent {
  constructor(className = ``) {
    super();

    this._className = className;
  }

  getTemplate() {
    return createTemplate(this._className);
  }
}
